import React from 'react'
import { IKImage } from 'imagekitio-react'
import { Fade } from "react-awesome-reveal";
import GoToTop from './GoToTop'

// TEAM DIRECTORS
// import DanielLaRosa from '../assets/TeamMembers/DanielLaRosa.jpg'
// import EmilyWright from '../assets/TeamMembers/EmilyWright.jpg'
import DanielAsadi from '../assets/TeamMembers/DanielAsadi.jpg'
import JakeSprenger from '../assets/TeamMembers/JakeSprenger.jpg'
import SamBahrami from '../assets/TeamMembers/SamBahrami.jpeg'

// MECHANICAL
import JasmineWu from '../assets/TeamMembers/Mech/JasmineWu.jpg'
import MauroDeLiberato from '../assets/TeamMembers/Mech/MauroDeLiberato.jpg'
import MoTaban from '../assets/TeamMembers/Mech/MoTaban.jpg'
import BatuTibet from '../assets/TeamMembers/Mech/BatuTibet.jpg'
import PatrykAniolowski from '../assets/TeamMembers/Mech/PatrykAniolowski.jpg'
import KelvinCao from '../assets/TeamMembers/Mech/KelvinCao.jpg'
import HunzalaRajput from '../assets/TeamMembers/Mech/HunzalaRajput.jpg'
import EricEmmenegger from '../assets/TeamMembers/Mech/EricEmmenegger.jpg'
import ToluIkubaje from '../assets/TeamMembers/Mech/ToluIkubaje.jpg'
import VanshikaBhatia from '../assets/TeamMembers/Mech/VanshikaBhatia.jpg'

// ELECTRICAL
import KateKer from '../assets/TeamMembers/Electrical/KateKer.jpg'
import NadiaZhou from '../assets/TeamMembers/Electrical/NadiaZhou.jpg'
import AngusZhang from '../assets/TeamMembers/Electrical/AngusZhang.jpg'
import NicholasBurley from '../assets/TeamMembers/Electrical/NicholasBurley.jpg'

// DRIVERLESS
import AlfredXue from '../assets/TeamMembers/DV/AlfredXue.jpg'
import ThomasZeger from '../assets/TeamMembers/DV/ThomasZeger.jpeg'
import ArthurXu from '../assets/TeamMembers/DV/ArthurXu.jpg'
import JustinLim from '../assets/TeamMembers/DV/JustinLim.jpg'
import YoussefElhadad from '../assets/TeamMembers/DV/YoussefElhadad.jpeg'

// BUSINESS
import CaleyMcNeill from '../assets/TeamMembers/Business/CaleyMcNeill.jpg'
import OliviaTworzyanski from '../assets/TeamMembers/Business/OliviaTworzyanski.jpg'
import AmeliaDease from '../assets/TeamMembers/Business/AmeliaDease.jpg'
import HalleTeh from '../assets/TeamMembers/Business/HalleTeh.jpg'
import RobertFirsov from '../assets/TeamMembers/Business/RobertFirsov.jpg'
import EvanTal from '../assets/TeamMembers/Business/EvanTal.jpg'
import RebeccaSchultz from '../assets/TeamMembers/Business/RebeccaSchultz.jpg'

const Team2024 = () => {
  const urlEndpoint = 'https://ik.imagekit.io/18gwys9x0/UTFR/TeamMembers/'

  return (
    <div className='w-full h-[100%] text-white bg-[#181818]'> 
        {/* DIRECTORS */}
        <Fade triggerOnce delay={0.2}>
        <p className='text-4xl font-bold flex justify-center pt-8'>Team Leadership</p>
        </Fade>
        <div className='h-full w-full about grid md:grid-cols-2 lg:grid-cols-5 px-3'>
          <div className='w-[100%] my-auto pt-6'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              {/* <img src={DanielLaRosa} alt="team" className='teamImg my-auto mx-auto w-[90%]' loading="lazy"/> */}
              <IKImage urlEndpoint={urlEndpoint} path="DanielLaRosa.jpg" className='teamImg'/>
              <p className='personName'>Daniel LaRosa</p>
              <p className='personTitle'>Team Principal & Mechanical Technical Director</p>
              <p className='personProgram'>Mech 2T3 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={JakeSprenger} alt="team" className='teamImg my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Jake Sprenger</p>
              <p className='personTitle'>Electrical Technical Director</p>
              <p className='personProgram'>ECE 2T3 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={DanielAsadi} alt="team" className='teamImg my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Daniel Asadi</p>
              <p className='personTitle'>Driverless Technical Director</p>
              <p className='personProgram'>Eng Sci 2T3 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={EvanTal} alt="team" className='teamImg my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Evan Tal</p>
              <p className='personTitle'>Business Director</p>
              <p className='personProgram'>Rotman 2T5</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={SamBahrami} alt="team" className='teamImg my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Sam Bahrami</p>
              <p className='personTitle'>Shop Manager</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
        </div>

        {/* MECHANICAL LEADS */}
        <Fade triggerOnce delay={100}>
        <p className='text-4xl font-bold flex justify-center pt-5'>Mechanical Leads</p>
        </Fade>
        <div className='h-full w-full about grid md:grid-cols-2 lg:grid-cols-5 px-3'>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={JasmineWu} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Jasmine Wu</p>
              <p className='personTitle'>Aerodynamics Lead</p>
              <p className='personProgram'>Eng Sci 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={MauroDeLiberato} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Mauro De Liberato</p>
              <p className='personTitle'>Chassis Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={VanshikaBhatia} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Vanshika Bhatia</p>
              <p className='personTitle'>Ergonomics Lead</p>
              <p className='personProgram'>Mech 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={MoTaban} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Mo Taban</p>
              <p className='personTitle'>Brakes Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={BatuTibet} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Batu Tibet</p>
              <p className='personTitle'>Composites Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={PatrykAniolowski} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Patryk Aniolowski</p>
              <p className='personTitle'>Suspension Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={KelvinCao} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Kelvin Cao</p>
              <p className='personTitle'>Drivetrain Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={HunzalaRajput} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Hunzala Rajput</p>
              <p className='personTitle'>Steering Lead</p>
              <p className='personProgram'>Mech 2T4 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={EricEmmenegger} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Eric Emmenegger</p>
              <p className='personTitle'>Powertrain Mechanical Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] pt-12.5 my-auto pt-6'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={ToluIkubaje} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Tolu Ikubaje</p>
              <p className='personTitle'>Driverless Mechatronics Lead</p>
              <p className='personProgram'>Mech 2T5 + PEY</p>
            </Fade>
          </div>
        </div>

        {/* ELECTRICAL LEADS */}
        <Fade triggerOnce delay={100}>
          <p className='text-4xl font-bold flex justify-center pt-5'>Electrical Leads</p>
        </Fade>
        <div className='h-full w-full about grid md:grid-cols-2 lg:grid-cols-5 px-3'>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={AngusZhang} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Angus Zhang</p>
              <p className='personTitle'>Powertrain Lead</p>
              <p className='personProgram'>ECE 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={KateKer} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Kate Ker</p>
              <p className='personTitle'>Harness Lead</p>
              <p className='personProgram'>ECE 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={NicholasBurley} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Nicholas Burley</p>
              <p className='personTitle'>Controllers Lead</p>
              <p className='personProgram'>ECE 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={NadiaZhou} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Nadia Zhou</p>
              <p className='personTitle'>Firmware Lead</p>
              <p className='personProgram'>ECE 2T6 + PEY</p>
            </Fade>
          </div>
        </div>

        {/* DRIVERLESS LEADS */}
        <Fade triggerOnce delay={100}>
          <p className='text-4xl font-bold flex justify-center pt-5'>Driverless Leads</p>
        </Fade>
        <div className='h-full w-full about grid md:grid-cols-2 lg:grid-cols-5 px-3'>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={AlfredXue} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Alfred Xue</p>
              <p className='personTitle'>Perception Co-Lead</p>
              <p className='personProgram'>Eng Sci 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={ThomasZeger} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Thomas Zeger</p>
              <p className='personTitle'>Perception Co-Lead</p>
              <p className='personProgram'>CE 2T4 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={ArthurXu} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Arthur Xu</p>
              <p className='personTitle'>Mapping Lead</p>
              <p className='personProgram'>ECE 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={JustinLim} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Justin Lim</p>
              <p className='personTitle'>Navigation Lead</p>
              <p className='personProgram'>Eng Sci 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={YoussefElhadad} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Youssef Elhadad</p>
              <p className='personTitle'>Controls Lead</p>
              <p className='personProgram'>CE 2T5 + PEY</p>
            </Fade>
          </div>
        </div>

        {/* BUSINESS LEADS */}
        <Fade triggerOnce delay={100}>
          <p className='text-4xl font-bold flex justify-center pt-5'>Business Leads</p>
        </Fade>
        <div className='h-full w-full about grid md:grid-cols-2 lg:grid-cols-5 px-3'>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={CaleyMcNeill} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Caley McNeill</p>
              <p className='personTitle'>Events Co-Lead</p>
              <p className='personProgram'>Indy 2T5 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={RebeccaSchultz} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Rebecca Schultz</p>
              <p className='personTitle'>Events Co-Lead</p>
              <p className='personProgram'>Rotman Commerce 2T4</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={OliviaTworzyanski} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Olivia Tworzyanski</p>
              <p className='personTitle'>Marketing Co-Lead</p>
              <p className='personProgram'>Kinesiology 2T3</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={300} damping={0.1} triggerOnce>
              <img src={AmeliaDease} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Amelia Dease</p>
              <p className='personTitle'>Marketing Co-Lead</p>
              <p className='personProgram'>Political Science 2T6</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={EvanTal} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Evan Tal</p>
              <p className='personTitle'>External Relations Lead</p>
              <p className='personProgram'>Rotman 2T5</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={HalleTeh} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Halle Teh</p>
              <p className='personTitle'>Recruitment Co-Lead</p>
              <p className='personProgram'>Indy 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={NicholasBurley} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Nicholas Burley</p>
              <p className='personTitle'>Recruitment Co-Lead</p>
              <p className='personProgram'>ECE 2T6 + PEY</p>
            </Fade>
          </div>
          <div className='w-[100%] my-auto py-10'>
            <Fade cascade delay={100} damping={0.1} triggerOnce>
              <img src={RobertFirsov} alt="team" className='my-auto mx-auto w-[90%]' loading="lazy"/>
              <p className='personName'>Robert Firsov</p>
              <p className='personTitle'>Recruitment Co-Lead</p>
              <p className='personProgram'>ECE 2T6 + PEY</p>
            </Fade>
          </div>
        </div>
        <GoToTop />
    </div>
  )
}

export default Team2024
